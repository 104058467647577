<template>
  <v-row class="px-4 py-2 d-flex align-center justify-space-between">
    <v-col cols="4">
      <UserAutocomplete
        v-if="$isAdmin() || ($isEmployee() && $can(['projects.*']))"
        v-model="params.user_id"
        :label="$t('time_tracking.labels.show_another_employee')"
        @input="submitFilters"
      />
    </v-col>

    <v-col cols="4" class="d-flex justify-center align-center">
      <v-btn icon @click="getWorkMonth(-1)">
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <span class="text-h6 text-center pa-2" style="min-width: 170px">
        {{ header }}
      </span>
      <v-btn icon @click="getWorkMonth(1)">
        <v-icon>chevron_right</v-icon>
      </v-btn>
    </v-col>

    <v-col cols="4" class="d-flex justify-center align-center">
      <div style="width: 36px">
        <v-tooltip v-if="offsetFromCurrentMonth !== 0" bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon text @click="returnToToday">
              <v-icon>
                {{ offsetFromCurrentMonth > 0 ? 'fast_forward' : 'fast_rewind' }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('time_tracking.labels.show_current_month') }}</span>
        </v-tooltip>
      </div>

      <v-text-field
        v-model="searchQuery"
        :label="$t('general.search')"
        class="pt-0 mt-0 px-3"
        prepend-inner-icon="search"
        clearable
        hide-details
        @input="onSearchInput"
      />
    </v-col>
  </v-row>
</template>

<script>
import UserAutocomplete from '@/components/autocompletes/UserAutocomplete';
import filterMixin from '@/mixins/filter-mixin';
import {
  addDays,
  addMonths,
  differenceInCalendarMonths,
  endOfMonth,
  format,
  isMonday,
  isSunday,
  isValid,
  nextSunday,
  previousMonday,
  startOfMonth,
} from 'date-fns';
import { getDefaultTimeTrackingFilterParams } from '@/store/modules/time-tracking-module';
import { mapGetters } from 'vuex';

export default {
  name: 'TimeTrackingFilter',

  components: { UserAutocomplete },

  props: {
    filterParams: {
      type: Object,
      default: () => ({}),
    },
  },

  mixins: [filterMixin],

  data() {
    return {
      searchQuery: '',
      searchDebounceTimerId: null,
    };
  },

  computed: {
    ...mapGetters('settings', ['defaultDateFnsConfig']),

    header() {
      if (!this.params.date_from || !isValid(new Date(this.params.date_from))) {
        return '';
      }

      return format(
        addDays(new Date(this.params.date_from), 15),
        'LLLL yyyy',
        this.defaultDateFnsConfig,
      );
    },

    offsetFromCurrentMonth() {
      if (!this.params.date_from || !isValid(new Date(this.params.date_from))) {
        return 0;
      }

      return differenceInCalendarMonths(
        new Date(),
        new Date(addDays(new Date(this.params.date_from), 15)),
      );
    },
  },

  methods: {
    // offset = how many months from current month to move.
    // currently used with -1 for previous month and 1 for next month
    getWorkMonth(offset) {
      const middleDate = addDays(new Date(this.params.date_from), 15);

      let dateFrom = startOfMonth(addMonths(middleDate, offset));
      let dateTo = endOfMonth(addMonths(middleDate, offset));

      if (!isMonday(dateFrom)) {
        dateFrom = previousMonday(dateFrom);
      }

      if (!isSunday(dateTo)) {
        dateTo = nextSunday(dateTo);
      }

      this.params.date_from = format(dateFrom, 'yyyy-MM-dd');
      this.params.date_to = format(dateTo, 'yyyy-MM-dd');
      this.submitFilters();
    },

    onSearchInput(value) {
      clearTimeout(this.searchDebounceTimerId);
      this.searchDebounceTimerId = setTimeout(() => {
        this.$emit('update:search', value);
      }, 500);
    },

    returnToToday() {
      const { date_from, date_to } = getDefaultTimeTrackingFilterParams();
      this.params.date_from = date_from;
      this.params.date_to = date_to;
      this.submitFilters();
    },
  },
};
</script>

<style scoped></style>
