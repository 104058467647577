<template>
  <v-container fluid>
    <v-tabs class="elevation-1 table-wrapper">
      <v-tab @click="fetchTimeTrackingEntries(timeTrackingFilterParams)">
        {{ $t('general.time_tracking') }}
      </v-tab>
      <v-tab-item>
        <h1 v-if="$vuetify.breakpoint.mdAndDown" class="text-subtitle-1 pa-4">
          {{ $t('general.page_unsupported_on_mobile') }}.
        </h1>

        <template v-else>
          <TimeTrackingFilter
            :filter-params="timeTrackingFilterParams"
            @change="$router.push({ name: 'timeTracking', query: $event })"
            @update:search="localSearchQuery = $event || ''"
          />

          <v-progress-circular
            v-if="loading['get:api/time-tracking'] && !timeTrackingEntriesByUser[selectedUserId]"
            class="ma-4"
            color="primary"
            indeterminate
          />

          <p
            v-else-if="
              timeTrackingEntriesByUser[selectedUserId] &&
              !timeTrackingEntriesByUser[selectedUserId].length
            "
            class="px-4 pt-4"
          >
            {{
              timeTrackingFilterParams.user_id
                ? $t('projects.user_has_no_assigned_projects')
                : $t('projects.you_have_no_assigned_projects')
            }}
          </p>

          <TimeTrackingTable
            v-else
            :loading="loading['get:api/time-tracking']"
            :projects="timeTrackingEntriesByUser[selectedUserId] || []"
            :filter-params="timeTrackingFilterParams"
            :user-id="selectedUserId"
            :search="localSearchQuery"
            :on-save="storeTimeTrackingEntry"
            can-change
            show-summary-row
            show-project-titles
            highlight-weekends
            highlight-hovered-row-and-column
          />
        </template>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import TimeTrackingTable from '@/components/tables/TimeTrackingTable';
import { mapActions, mapGetters, mapState } from 'vuex';
import { getSanitizedFilterParams } from '@/util/filter-params';
import TimeTrackingFilter from '@/components/filters/TimeTrackingFilter';
import { getDefaultTimeTrackingFilterParams } from '@/store/modules/time-tracking-module';

export default {
  name: 'TimeTracking',

  components: {
    TimeTrackingFilter,
    TimeTrackingTable,
  },

  data() {
    return {
      localSearchQuery: '',
    };
  },

  computed: {
    ...mapState('auth', ['currentUser']),
    ...mapState('timeTracking', ['timeTrackingEntriesByUser', 'timeTrackingFilterParams']),
    ...mapGetters(['loading']),

    selectedUserId() {
      return this.timeTrackingFilterParams.user_id || this.currentUser.id;
    },
  },

  created() {
    let params = getSanitizedFilterParams(this.$route.query);
    if (!params.date_from || !params.date_to) {
      params = getDefaultTimeTrackingFilterParams();
    }
    this.fetchTimeTrackingEntries(params);
  },

  async beforeRouteUpdate(to, from, next) {
    const params = getSanitizedFilterParams(to.query);
    if (
      JSON.stringify(this.timeTrackingFilterParams) !== JSON.stringify(params) &&
      to.name === 'timeTracking'
    ) {
      await this.fetchTimeTrackingEntries(to.query);
    }
    next();
  },

  methods: {
    ...mapActions('timeTracking', [
      'fetchTimeTrackingEntries',
      'storeTimeTrackingEntry',
      'fetchTimeTrackingEntries',
    ]),
  },
};
</script>
